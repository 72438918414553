import { WhoMadeThisPage as SDSWhoMadeThisPage } from 'sds-projects';

export function WhoMadeThisPage() {
  return (
    <SDSWhoMadeThisPage
      customTheme={{
        darkLogo: true,
        colors: {
          text: 'text-gray-300',
        },
      }}
    />
  );
}
