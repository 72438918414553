import { Clone, useGLTF } from '@react-three/drei'

export default function Ball({ radius }) {
  const { nodes } = useGLTF('./models/banana.glb')

  return (
    <mesh castShadow>
      <Clone object={nodes.Cylinder} rotation={[Math.PI, 0, 0]} scale={0.075} />
    </mesh>
  )
}
