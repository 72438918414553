import { useState } from 'react'

import { Vector3 } from 'three'
import { useFrame } from '@react-three/fiber'
import { Html, Billboard, useTexture, Plane } from '@react-three/drei'

import useKeyboard from './useKeyboard'
import { useNPCData } from './useNPCData'


function SimpleDialogPanel({ text }) {
    return (
        <div>{text}</div>
    )
}

function DialogPanelWithChoices({ text, choices }) {
    const keys = ["J", "K", "L"]

    return (
        <div>
            {text}
            <hr />
            {choices.map((choice, index) => <p key={index}>{keys[index]} - {choice}</p>)}
        </div>
    )
}


export default function NPC ({ characterKey, spriteUrl, position, scale, updateProgress, updateHealth }) {
    const { name, description, prompt, coreValue, choices, responses, correctIndex, smallTalk } = useNPCData(characterKey)

    const positionVector = new Vector3(...position)
    const [interacting, setInteracting] = useState(true)
    const [choice, setChoice] = useState(null)
    const [done, setDone] = useState(false)

    const keyboard = useKeyboard()

    const texture = useTexture(spriteUrl)

    useFrame(({ camera }) => {
        if (interacting) {
            if (camera.position.distanceTo(positionVector) > 3) {
                setInteracting(false)
                if (choice !== null && !done) {
                    setDone(true)
                }
            }

            if (choice === null) {
                if (keyboard["KeyJ"]) {
                    setChoice(0)
                    if (correctIndex === 0) {
                        updateProgress(coreValue)
                        updateHealth(10)
                    } else {
                        updateHealth(-20)
                    }
                }
    
                if (keyboard["KeyK"]) {
                    setChoice(1)
                    if (correctIndex === 1) {
                        updateProgress(coreValue)
                        updateHealth(10)
                    } else {
                        updateHealth(-20)
                    }
                }
                
                if (keyboard["KeyL"]) {
                    setChoice(2)
                    if (correctIndex === 2) {
                        updateProgress(coreValue)
                        updateHealth(10)
                    } else {
                        updateHealth(-20)
                    }
                }
            }
            
        } else {
            if (camera.position.distanceTo(positionVector) < 3) {
                setInteracting(true)
            }
        }
    })

	return (
        <>
            <Billboard
                position={position}
                scale={0.2 * scale}
            >
                <Plane
                    args={[texture.source.data.naturalWidth / 120, texture.source.data.naturalHeight / 120]}
                >
                    <meshBasicMaterial transparent map={texture} />
                </Plane>
             </Billboard>
            {interacting &&
                <Html
                    position={[position[0], position[1] + 0.5, position[2]]}
                    wrapperClass='interactionModal'
                >
                    <div>
                        <span style={{color: 'yellow'}}>{name}{' '}</span>
                        <span style={{color: 'gray'}}>{description}</span>
                    </div>
                    <div className="spacer" />
                    {interacting && choice === null &&
                        <DialogPanelWithChoices
                            text={prompt}
                            choices={choices}
                        />
                    }

                    {interacting && choice !== null && !done &&
                        <SimpleDialogPanel text={responses[choice]} />
                    }

                    {interacting && choice !== null && done &&
                        <SimpleDialogPanel text={smallTalk} />
                    }

                </Html>
            }
        </>
	)
}
