export function LandingPage() {
  return (
    <main className="flex flex-col h-[92vh] items-center justify-around text-neon p-12">
      <header className="text-center">
        <h1 className="text-5xl mb-4">Armageddon Agents</h1>
        <p>
          LA may be a dystopian wasteland, but it's just another day at the
          office for AE Studio.
          <br />
          Help your clients and fellow agents by embodying our core values!
        </p>
      </header>
      <img src="/img/hero.png" alt="Game preview" className="w-1/2" />
      <a
        href="/play"
        className="text-5xl font-semibold bg-neon/10 border border-neon rounded-sm px-12 py-6 hover:bg-neon/20 transition-colors"
      >
        Play
      </a>
      <footer className="text-lg">
        Made with 💚 at{' '}
        <a
          href="https://ae.studio?utm_source=armageddonagents.com"
          target="_blank"
          rel="noreferrer"
          className="hover:underline"
        >
          AE Studio
        </a>
      </footer>
    </main>
  );
}
