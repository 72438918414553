export default function Overlay({ progress, health }) {
  return (
    <>
      <div id="hud-title">BCI OS v420.0.1</div>
      <div id="hud-instructions">
        Look around with mouse/trackpad
        <br />
        W,A,S,D to move
        <br />
        Click for banana
      </div>
      <div id="hud-progress">{progress} / 5 core values utilized</div>
      <div id="hud-health">
        {health > 20 && (
          <>
            <span id="hud-health-label">Agency </span>
            <progress id="hud-health-bar" value={health} max={100} />
          </>
        )}
        {health <= 20 && (
          <>
            <span id="hud-health-label-backup">
              Warning: Backup state detected{' '}
            </span>
            <progress id="hud-health-bar-backup" value={health} max={100} />
          </>
        )}
      </div>
      <div id="hud-border" />
      <div id="hud-ae">
        Made with 💚 at{' '}
        <a
          href="https://ae.studio?utm_source=armageddonagents.com"
          target="_blank"
          rel="noreferrer"
        >
          AE Studio
        </a>
      </div>
    </>
  );
}
