import {
  BuildingOfficeIcon,
  CurrencyDollarIcon,
  HomeIcon,
} from '@heroicons/react/24/outline';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { SdsNavbar } from 'sds-projects';
import { LandingPage } from './LandingPage';
import { PlayerPage } from './PlayerPage';
import { PricingPage } from './PricingPage';
import { WhoMadeThisPage } from './WhoMadeThisPage';

const navigation = [
  { name: 'Home', page: '/', icon: HomeIcon },
  { name: 'Pricing', page: '/pricing', icon: CurrencyDollarIcon },
  {
    name: 'Who made this?',
    page: '/who-made-this',
    icon: BuildingOfficeIcon,
  },
];

const navbarTheme = {
  darkLogo: true,
  colors: {
    background: 'bg-black',
    border: 'border-neon',
    tabs: 'text-neon hover:bg-neon/20',
    hover: 'hover:bg-neon/20',
    // backdrop: 'bg-gray-900/80',
    // text: 'text-white',
    // ring: 'ring-black/10',
    // active: 'bg-gray-100',
    // primaryButton: 'text-white transition-colors hover:bg-white bg-[#f99556]',
    // itemNotAvailable: 'bg-gray-100',
    dropdown: 'bg-black text-neon font-normal ring-neon hover:bg-neon/20',
    dropdownItem: 'bg-black divide-neon ring-neon border border-neon',
    dropdownText: 'text-neon hover:bg-neon/20',
  },
};

export default function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <LandingPage />,
    },
    {
      path: '/play',
      element: <PlayerPage />,
    },
    {
      path: '/pricing',
      element: <PricingPage />,
    },
    {
      path: '/who-made-this',
      element: <WhoMadeThisPage />,
    },
  ]);

  return (
    <div className="font-display">
      <SdsNavbar
        projectName="Armageddon Agents"
        navigation={navigation}
        customTheme={navbarTheme}
        hideUserMenu
        onClick
      >
        <div className="w-full h-full">
          <RouterProvider router={router} />
        </div>
      </SdsNavbar>
    </div>
  );
}
