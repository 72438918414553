import { useRef } from 'react'

import { useGLTF } from '@react-three/drei'
import useOctree from './useOctree'
import Player from './Player'
import SphereCollider from './SphereCollider'
import Ball from './Ball'
import * as Constants from './Constants'

import NPC from './NPC'
import SimpleNPC from './SimpleNPC'
import Interactable from './Interactable'


export default function Game({ updateHealth, updateProgress }) {
  const { scene } = useGLTF('./models/factory.glb')
  const octree = useOctree(scene)

  const colliders = useRef([])

  function checkSphereCollisions(sphere, velocity) {
    for (let i = 0, length = colliders.current.length; i < length; i++) {
      const c = colliders.current[i]

      if (c.sphere) {
        const d2 = sphere.center.distanceToSquared(c.sphere.center)
        const r = sphere.radius + c.sphere.radius
        const r2 = r * r

        if (d2 < r2) {
          // eslint-disable-next-line
          const normal = Constants.v1.subVectors(sphere.center, c.sphere.center).normalize()
          const impact1 = Constants.v2.copy(normal).multiplyScalar(normal.dot(velocity))
          const impact2 = Constants.v3.copy(normal).multiplyScalar(normal.dot(c.velocity))
          velocity.add(impact2).sub(impact1)
          c.velocity.add(impact1).sub(impact2)
          const d = (r - Math.sqrt(d2)) / 2
          sphere.center.addScaledVector(normal, d)
          c.sphere.center.addScaledVector(normal, -d)
        }
      } else if (c.capsule) {
        const center = Constants.v1.addVectors(c.capsule.start, c.capsule.end).multiplyScalar(0.5)
        const r = sphere.radius + c.capsule.radius
        const r2 = r * r
        for (const point of [c.capsule.start, c.capsule.end, center]) {
          const d2 = point.distanceToSquared(sphere.center)
          if (d2 < r2) {
            const normal = Constants.v1.subVectors(point, sphere.center).normalize()
            const impact1 = Constants.v2.copy(normal).multiplyScalar(normal.dot(c.velocity))
            const impact2 = Constants.v3.copy(normal).multiplyScalar(normal.dot(velocity))
            c.velocity.add(impact2).sub(impact1)
            velocity.add(impact1).sub(impact2)
            const d = (r - Math.sqrt(d2)) / 2
            sphere.center.addScaledVector(normal, -d)
          }
        }
      }
    }
  }

  return (
    <>
      <group dispose={null}>
        <primitive object={scene} />
      </group>
      {Constants.balls.map(({ position }, i) => (
        <SphereCollider key={i} id={i} radius={Constants.radius} octree={octree} position={position} colliders={colliders.current} checkSphereCollisions={checkSphereCollisions}>
          <Ball radius={Constants.radius} />
        </SphereCollider>
      ))}
      <Player ballCount={Constants.ballCount} octree={octree} colliders={colliders.current} />
      <NPC
        characterKey="ed"
        spriteUrl="./img/ed-sprite.png"
        position={[4, 1.3, 3]}
        scale={1}
        updateProgress={updateProgress}
        updateHealth={updateHealth}
      />
      <NPC
        characterKey="rhino"
        spriteUrl="./img/gunkclaw-sprite.png"
        position={[13, 2.1, 8]}
        scale={1.8}
        updateProgress={updateProgress}
        updateHealth={updateHealth}
      />
      <NPC
        characterKey="vapor"
        spriteUrl="./img/vapor-sprite.png"
        position={[25, 1.4, -12]}
        scale={1.1}
        updateProgress={updateProgress}
        updateHealth={updateHealth}
      />

      <NPC
        characterKey="amalgamate"
        spriteUrl="./img/amalgamate-sprite.png"
        position={[40, 1.4, -8]}
        scale={4}
        updateProgress={updateProgress}
        updateHealth={updateHealth}
      />

      <NPC
        characterKey="mgmt"
        spriteUrl="./img/mgmt-sprite.png"
        position={[28, 1.9, 7]}
        scale={1.2}
        updateProgress={updateProgress}
        updateHealth={updateHealth}
      />

      <SimpleNPC
        spriteUrl="./img/fang-sprite.png"
        position={[30, 1.4, -3]}
        scale={1.1}
        name="Fang Rotlip"
        description="???"
        smallTalk="Mondays...am I right?"
      />

      <SimpleNPC
        spriteUrl="./img/dank-sprite.png"
        position={[3, 1.4, -12]}
        scale={1.1}
        name="Dank Deathtoad"
        description="TPM, literal freak"
        smallTalk="Hey, you seem cool, let's grab a sludge cocktail at the next retreat!"
      />

      <Interactable
        spriteUrl="./img/acaraje.png"
        position={[18, 1, 6]}
        scale={2}
        healthEffect={5}
        updateHealth={updateHealth}
        effectiveRange={1.5}
        isOneTime={true}
      />

      <Interactable
        spriteUrl="./img/acaraje.png"
        position={[20, 1, -3]}
        scale={2}
        healthEffect={5}
        updateHealth={updateHealth}
        effectiveRange={1.5}
        isOneTime={true}
      />

      <Interactable
        spriteUrl="./img/acaraje.png"
        position={[40, 1, -3]}
        scale={2}
        healthEffect={5}
        updateHealth={updateHealth}
        effectiveRange={1.5}
        isOneTime={true}
      />

      <Interactable
        spriteUrl="./img/braingoo.png"
        position={[22, 1, -4]}
        scale={2}
        healthEffect={5}
        updateHealth={updateHealth}
        effectiveRange={1.5}
        isOneTime={true}
      />

      <Interactable
        spriteUrl="./img/braingoo.png"
        position={[25, 1, 8]}
        scale={2}
        healthEffect={5}
        updateHealth={updateHealth}
        effectiveRange={1.5}
        isOneTime={true}
      />

      <Interactable
        spriteUrl="./img/braingoo.png"
        position={[43, 1, -14]}
        scale={2}
        healthEffect={5}
        updateHealth={updateHealth}
        effectiveRange={1.5}
        isOneTime={true}
      />

      <Interactable
        spriteUrl="./img/nuclear-waste.png"
        position={[15, 1.2, -6]}
        scale={2}
        healthEffect={-3}
        updateHealth={updateHealth}
        effectiveRange={3.5}
        isOneTime={false}
      />
    </>
  )
}
