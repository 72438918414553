import { useState } from 'react'

import { Vector3 } from 'three'
import { useFrame } from '@react-three/fiber'
import { Html, Billboard, useTexture, Plane } from '@react-three/drei'

function SimpleDialogPanel({ text }) {
    return (
        <div>{text}</div>
    )
}

export default function SimpleNPC ({ spriteUrl, position, scale, name, description, smallTalk }) {
    const positionVector = new Vector3(...position)
    const [interacting, setInteracting] = useState(true)

    const texture = useTexture(spriteUrl)

    useFrame(({ camera }) => {
        if (interacting) {
            if (camera.position.distanceTo(positionVector) > 3) {
                setInteracting(false)
            }    
        } else {
            if (camera.position.distanceTo(positionVector) < 3) {
                setInteracting(true)
            }
        }
    })

	return (
        <>
            <Billboard
                position={position}
                scale={0.2 * scale}
            >
                <Plane
                    args={[texture.source.data.naturalWidth / 120, texture.source.data.naturalHeight / 120]}
                >
                    <meshBasicMaterial transparent map={texture} />
                </Plane>
             </Billboard>
            {interacting &&
                <Html
                    position={[position[0], position[1] + 0.5, position[2]]}
                    wrapperClass='interactionModal'
                >
                    <div>
                        <span style={{color: 'yellow'}}>{name}{' '}</span>
                        <span style={{color: 'gray'}}>{description}</span>
                    </div>
                    <div className="spacer" />

                    {interacting &&
                        <SimpleDialogPanel text={smallTalk} />
                    }

                </Html>
            }
        </>
	)
}
