import { Suspense, useState } from 'react';

import {
  Environment,
  Html,
  PointerLockControls,
  useProgress,
} from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import Game from './Game';
import Overlay from './Overlay';

function Loader({ setCanStart }) {
  const { active, total } = useProgress();

  if (!active) {
    setCanStart(true);
  }

  return (
    <Html center wrapperClass="intro">
      Booting up your BCI OS: {Math.round(total)} % loaded
      <br />
      <br />
      The year is 2093
      <br />
      <br />
      LA may be a dystopian wasteland, but it's just another day at the office
      for AE Studio.
      <br />
      <br />
      Help your clients and fellow agents by embodying our core values!
    </Html>
  );
}

export function PlayerPage() {
  const [progress, setProgress] = useState([]);
  const [health, setHealth] = useState(100.0);
  const [dead, setDead] = useState(false);
  const [canStart, setCanStart] = useState(false);

  const updateHealth = (amount) => {
    const newHealth = Math.min(health + amount, 100.0);
    setHealth(newHealth);
    if (newHealth <= 0) {
      setDead(true);
    }
  };

  const updateProgress = (coreValue) => {
    setProgress([...progress, coreValue]);
  };

  if (dead) {
    return (
      <div id="game-over">
        The wasteland is cruel and unrelenting. Reload the page to try again!
      </div>
    );
  } else {
    return (
      <div className="relative h-[93vh]">
        <Canvas>
          <Suspense fallback={<Loader setCanStart={setCanStart} />}>
            <ambientLight />
            <Environment files={'./img/sky.hdr'} background />
            <Game updateHealth={updateHealth} updateProgress={updateProgress} />
            <PointerLockControls />
          </Suspense>
        </Canvas>
        {canStart && <Overlay progress={progress.length} health={health} />}
      </div>
    );
  }
}
