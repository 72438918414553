import { useState, useRef } from 'react'

import { Vector3 } from 'three'
import { useFrame } from '@react-three/fiber'
import { Billboard, useTexture, Plane } from '@react-three/drei'


export default function Interactable ({ spriteUrl, position, scale, healthEffect, updateHealth, effectiveRange, isOneTime }) {
    const positionVector = new Vector3(...position)
    const texture = useTexture(spriteUrl)

    const [done, setDone] = useState(false)
    const cooldown = useRef(0)
    const myRef = useRef()

    const hide = () => {
        if (myRef.current) {
            myRef.current.visible = false
        }
    }

    useFrame(({ camera }) => {
        if (cooldown.current > 0) {
            cooldown.current = cooldown.current - 1
        } else {
            if (!done) {
                if (camera.position.distanceTo(positionVector) < effectiveRange) {
                    // Apply effect
                    updateHealth(healthEffect)
                    if (isOneTime) {
                        setDone(true)
                        hide()
                    }
                    cooldown.current = 10
                }
            }
        }
    })

	return (
        <>
            <Billboard
                position={position}
                scale={0.2 * scale}
                ref={myRef}
            >
                <Plane
                    args={[texture.source.data.naturalWidth / 120, texture.source.data.naturalHeight / 120]}
                >
                    <meshBasicMaterial transparent map={texture} />
                </Plane>
             </Billboard>
        </>
	)
}
