import { PricingPage as SDSPricingPage } from 'sds-projects';

const features = ['Play as much as you want', 'Embody our core values'];

export function PricingPage() {
  return (
    <div className="text-center text-neon">
      <h2 className="text-3xl mt-12">Simple pricing, no commitment.</h2>
      <p>Everything you need, nothing you don't.</p>
      <SDSPricingPage
        customTheme={{
          colors: {
            text: 'text-white',
            primaryButton:
              'text-neon border border-neon hover:opacity-80 hover:bg-neon/20',
            secondaryButton:
              'cursor-not-allowed text-neon/30 border border-neon/10',
            icon: 'text-neon',
            primaryCard: 'bg-neon/20 border border-neon',
            secondaryCard: 'bg-neon/5 border border-neon',
            badge: 'text-neon bg-neon/20',
          },
        }}
        freeTierFeatures={features}
      />
      <footer className="text-lg">
        Made with 💚 at{' '}
        <a
          href="https://ae.studio?utm_source=armageddonagents.com"
          target="_blank"
          rel="noreferrer"
          className="hover:underline"
        >
          AE Studio
        </a>
      </footer>
    </div>
  );
}
