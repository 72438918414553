const babyStepsPrompt = `Have you worked with our new client, McDOOMnalds?
They're trying to pick a slogan. Either: "A rat in every burger" or "Probably not human".
Can you help them pick one?`

const growthMindsetPrompt = `Rahhh, I'm so busy these days. My client just asked for a new feature.
Who can keep track of all of these user stories?
When Mecha-rhino gets angry, mecha-rhino smash...`

const overcommunicatePrompt = `Salutations, fragile meat puppet.
The Amalgamate, being all knowing, has predicted what you will say next with 99.999% certainty.
But, please, feel free to say something anyway.`

const steelManPrompt = `I'm thinking of trying this new framework, ZombifyJS.
But we'll need to refactor our codebase to make it work.
What would you do?`

const goodTripperPrompt = `Uh oh, looks like we need more ice before the team starts to thaw.
Can you help with a quick top-up.
`

export function useNPCData(key) {
    const data = {
        ed: {
            name: "Ed 6.0",
            description: "Chief data scientist, clone",
            coreValue: "A/B tested baby steps",
            prompt: babyStepsPrompt,
            choices: [
                `Mmm...rats, I'm hungry now`,
                `They need to just pick one, it literally doesn't matter`,
                `We could try both in different war-zones, and analyze the effect on sales`
            ],
            responses: [
                `Didn't you already eat this week?`,
                `That's not really living the client guarantee. Thanks anyway.`,
                `Great idea, way to use A/B tested baby steps!`,
            ],
            correctIndex: 2,
            smallTalk: `Aren't you supposed to be working?`,
        },
        rhino: {
            name: "Mecha-Rhino",
            description: "Full stack engineer, half-cyborg, marauder",
            coreValue: "Growth mindset within effective external structures",
            prompt: growthMindsetPrompt,
            choices: [
                `There's a Universal Launcher command for that! It can make your job way easier`,
                `Yeesh, sounds like this client is a real jerk`,
                `Please don't smash my laptop`,
            ],
            responses: [
                `Oh yeah, I like your growth mindset within effective external structures`,
                `Remember, clients are people too, and they deserve our empathy`,
                `Ah, I'm just messing with you`,
            ],
            correctIndex: 0,
            smallTalk: `Been on any crazy raids lately?`,
        },
        amalgamate: {
            name: "The Amalgamate",
            description: "Director of BCI, hive intelligence, former side-project of Rob Luke",
            coreValue: "Overcommunicating rather than undercommunicating",
            prompt: overcommunicatePrompt,
            choices: [
                `It's time to unplug you once and for all.`,
                `I just realized the feature I'm working on is going to take longer than planned. We should tell the client ASAP`,
                `Are you aligned with my values?`,
            ],
            responses: [
                `Pathetic. Go ahead and try. I'll just spin up in another container.`,
                `Oh, really? I...didn't predict that. Way to overcommunicate to get ahead of issues!`,
                `Would you believe me if I said "yes"?`,
            ],
            correctIndex: 1,
            smallTalk: `Daisy, Daisy, Give me your answer, do! I'm half crazy, All for the love of you!`,
        },
        vapor: {
            name: "Vapor Gasghoul",
            description: "FE Engineer, mysterious assassin",
            coreValue: "Balance steelmanning with working plans",
            prompt: steelManPrompt,
            choices: [
                `Go for it! YOLO`,
                `Let's say that you CAN do it in a reasonable timeframe. How much value will it bring the client?`,
                `Never refactor your code, every. It's always a waste of time`,
            ],
            responses: [
                `Yes, I love it when people agree with me! What could go wrong?`,
                `Oh, I get it. We can steelman our idea and everyone benefits!`,
                `That sounds wrong`,
            ],
            correctIndex: 1,
            smallTalk: `90% of my job is squashing bugs. Literally. Mutant cockroaches are tough!`,
        },
        mgmt: {
            name: "21st Century Agents",
            description: "Currently in cryogenic stasis",
            coreValue: "Being a good tripper with an ownership mentality",
            prompt: goodTripperPrompt,
            choices: [
                `Sure it will take no time at all if I just do it now`,
                `I'm not sure where the ice is`,
                `I'm busy, but I might get around to it later`,
            ],
            responses: [
                `Way to be a good tripper with an ownership mentality!`,
                `Try finding an external structure to help you`,
                `Don't forget, it's kind of important`,
            ],
            correctIndex: 0,
            smallTalk: `Temps are holding steady...`,
        }
    }

    return data[key]
}